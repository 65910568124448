<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Bairro:</b> {{ neighborhood.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Cidade:</b> {{ city ? city.name : 'Carregando...' }}
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getCityById } from '@/modules/city/city.service';

export default {
  name: 'GetecmaNeighborhoodViewInformation',
  props: {
    neighborhood: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      city: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCityById(this.neighborhood.city_id)
        .then(data => {
          this.city = data;
        })
        .catch(() => toastError('Erro ao obter bairro por ID'));
    },
  },
};
</script>
